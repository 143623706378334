<template>
  <div class="imgBox min-w-1400">
    <!-- <div class="imgBox1 min-w-1400" @click="toggleNewImage(1)">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div> -->
    <div class="imgBox2 min-w-1400" @click="toggleNewImage(2)">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" alt="" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox30 min-w-1400">
      <div class="img30 max-w-1400">Conoce más de nosotros</div>
    </div>
    <div class="imgBox3 min-w-1400" @click="toggleNewImage(3)">
      <div class="img3 max-w-1400">
        <img
          :src="showNewImage3 ? AnswerImg3 : importedImg3"
          :alt="altText"
          class="imgItem3"
        />
      </div>
    </div>
    <div class="imgBox4 min-w-1400" @click="toggleNewImage(4)">
      <div class="img4 max-w-1400">
        <img
          :src="showNewImage4 ? AnswerImg4 : importedImg4"
          :alt="altText"
          class="imgItem4"
        />
      </div>
    </div>
    <div class="imgBox5 min-w-1400" @click="toggleNewImage(5)">
      <div class="img5 max-w-1400">
        <img
          :src="showNewImage5 ? AnswerImg5 : importedImg5"
          :alt="altText"
          class="imgItem5"
        />
      </div>
    </div>
    <div class="imgBox6 min-w-1400" @click="toggleNewImage(6)">
      <div class="img6 max-w-1400">
        <img
          :src="showNewImage6 ? AnswerImg6 : importedImg6"
          :alt="altText"
          class="imgItem6"
        />
      </div>
    </div>
    <div class="imgBox7 min-w-1400" @click="toggleNewImage(7)">
      <div class="img7 max-w-1400">
        <img
          :src="showNewImage7 ? AnswerImg7 : importedImg7"
          :alt="altText"
          class="imgItem7"
        />
      </div>
    </div>
    <div class="imgBox30 min-w-1400">
      <div class="img30 max-w-1400">Conoce más de nosotros</div>
    </div>
    <div class="imgBox8 min-w-1400" @click="toggleNewImage(8)">
      <div class="img8 max-w-1400">
        <img
          :src="showNewImage8 ? AnswerImg8 : importedImg8"
          :alt="altText"
          class="imgItem8"
        />
      </div>
    </div>
    <div class="imgBox9 min-w-1400" @click="toggleNewImage(9)">
      <div class="img9 max-w-1400">
        <img
          :src="showNewImage9 ? AnswerImg9 : importedImg9"
          :alt="altText"
          class="imgItem9"
        />
      </div>
    </div>
    <div class="imgBox10 min-w-1400" @click="toggleNewImage(10)">
      <div class="img10 max-w-1400">
        <img
          :src="showNewImage10 ? AnswerImg10 : importedImg10"
          :alt="altText"
          class="imgItem10"
        />
      </div>
    </div>
    <div class="imgBox11 min-w-1400" @click="toggleNewImage(11)">
      <div class="img11 max-w-1400">
        <img
          :src="showNewImage11 ? AnswerImg11 : importedImg11"
          :alt="altText"
          class="imgItem11"
        />
      </div>
    </div>
    <div class="imgBox12 min-w-1400" @click="toggleNewImage(12)">
      <div class="img12 max-w-1400">
        <img
          :src="showNewImage12 ? AnswerImg12 : importedImg12"
          :alt="altText"
          class="imgItem12"
        />
      </div>
    </div>
    <div class="imgBox30 min-w-1400">
      <div class="img30 max-w-1400">Conoce más de nosotros</div>
    </div>
    <div class="imgBox13 min-w-1400" @click="toggleNewImage(13)">
      <div class="img13 max-w-1400">
        <img
          :src="showNewImage13 ? AnswerImg13 : importedImg13"
          :alt="altText"
          class="imgItem13"
        />
      </div>
    </div>
    <div class="imgBox14 min-w-1400" @click="toggleNewImage(14)">
      <div class="img14 max-w-1400">
        <img
          :src="showNewImage14 ? AnswerImg14 : importedImg14"
          :alt="altText"
          class="imgItem14"
        />
      </div>
    </div>
    <div class="imgBox15 min-w-1400" @click="toggleNewImage(15)">
      <div class="img15 max-w-1400">
        <img
          :src="showNewImage15 ? AnswerImg15 : importedImg15"
          :alt="altText"
          class="imgItem15"
        />
      </div>
    </div>
    <div class="imgBox16 min-w-1400" @click="toggleNewImage(16)">
      <div class="img16 max-w-1400">
        <img
          :src="showNewImage16 ? AnswerImg16 : importedImg16"
          :alt="altText"
          class="imgItem16"
        />
      </div>
    </div>
    <div class="imgBox17 min-w-1400" @click="toggleNewImage(17)">
      <div class="img17 max-w-1400">
        <img
          :src="showNewImage17 ? AnswerImg17 : importedImg17"
          :alt="altText"
          class="imgItem17"
        />
      </div>
    </div>
    <div class="imgBox30 min-w-1400">
      <div class="img30 max-w-1400">Conoce más de nosotros</div>
    </div>
    <div class="imgBox18 min-w-1400" @click="toggleNewImage(18)">
      <div class="img18 max-w-1400">
        <img
          :src="showNewImage18 ? AnswerImg18 : importedImg18"
          :alt="altText"
          class="imgItem18"
        />
      </div>
    </div>
    <div class="imgBox19 min-w-1400" @click="toggleNewImage(19)">
      <div class="img19 max-w-1400">
        <img
          :src="showNewImage19 ? AnswerImg19 : importedImg19"
          :alt="altText"
          class="imgItem19"
        />
      </div>
    </div>
    <div class="imgBox20 min-w-1400" @click="toggleNewImage(20)">
      <div class="img20 max-w-1400">
        <img
          :src="showNewImage20 ? AnswerImg20 : importedImg20"
          :alt="altText"
          class="imgItem20"
        />
      </div>
    </div>
    <div class="imgBox21 min-w-1400" @click="toggleNewImage(21)">
      <div class="img21 max-w-1400">
        <img
          :src="showNewImage21 ? AnswerImg21 : importedImg21"
          :alt="altText"
          class="imgItem21"
        />
      </div>
    </div>
    <div class="imgBox22 min-w-1400" @click="toggleNewImage(22)">
      <div class="img22 max-w-1400">
        <img
          :src="showNewImage22 ? AnswerImg22 : importedImg22"
          :alt="altText"
          class="imgItem22"
        />
      </div>
    </div>
    <div class="imgBox30 min-w-1400">
      <div class="img30 max-w-1400">Conoce más de nosotros</div>
    </div>
    <div class="imgBox23 min-w-1400" @click="toggleNewImage(23)">
      <div class="img23 max-w-1400">
        <img
          :src="showNewImage23 ? AnswerImg23 : importedImg23"
          :alt="altText"
          class="imgItem23"
        />
      </div>
    </div>
    <div class="imgBox24 min-w-1400" @click="toggleNewImage(24)">
      <div class="img24 max-w-1400">
        <img
          :src="showNewImage24 ? AnswerImg24 : importedImg24"
          :alt="altText"
          class="imgItem24"
        />
      </div>
    </div>
    <div class="imgBox25 min-w-1400" @click="toggleNewImage(25)">
      <div class="img25 max-w-1400">
        <img
          :src="showNewImage25 ? AnswerImg25 : importedImg25"
          :alt="altText"
          class="imgItem25"
        />
      </div>
    </div>
    <div class="imgBox26 min-w-1400" @click="toggleNewImage(26)">
      <div class="img26 max-w-1400">
        <img
          :src="showNewImage26 ? AnswerImg26 : importedImg26"
          :alt="altText"
          class="imgItem26"
        />
      </div>
    </div>
    <div class="imgBox27 min-w-1400" @click="toggleNewImage(27)">
      <div class="img27 max-w-1400">
        <img
          :src="showNewImage27 ? AnswerImg27 : importedImg27"
          :alt="altText"
          class="imgItem27"
        />
      </div>
    </div>
    <div class="imgBox28 min-w-1400" @click="toggleNewImage(28)">
      <div class="img28 max-w-1400">
        <!-- <img
          :src="showNewImage28 ? AnswerImg28 : importedImg28"
          :alt="altText"
          class="imgItem28"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";

import importedImg2 from "@/page/agorarecursos/components/img/3_1.png";
import importedImg3 from "@/page/agorarecursos/components/img/3_3.png";
import importedImg4 from "@/page/agorarecursos/components/img/3_4.png";
import importedImg5 from "@/page/agorarecursos/components/img/3_5.png";
import importedImg6 from "@/page/agorarecursos/components/img/3_6.png";
import importedImg7 from "@/page/agorarecursos/components/img/3_7.png";
import importedImg8 from "@/page/agorarecursos/components/img/3_8.png";
import importedImg9 from "@/page/agorarecursos/components/img/3_9.png";
import importedImg10 from "@/page/agorarecursos/components/img/3_10.png";
import importedImg11 from "@/page/agorarecursos/components/img/3_11.png";
import importedImg12 from "@/page/agorarecursos/components/img/3_12.png";
import importedImg13 from "@/page/agorarecursos/components/img/3_13.png";
import importedImg14 from "@/page/agorarecursos/components/img/3_14.png";
import importedImg15 from "@/page/agorarecursos/components/img/3_15.png";
import importedImg16 from "@/page/agorarecursos/components/img/3_16.png";
import importedImg17 from "@/page/agorarecursos/components/img/3_17.png";
import importedImg18 from "@/page/agorarecursos/components/img/3_18.png";
import importedImg19 from "@/page/agorarecursos/components/img/3_19.png";
import importedImg20 from "@/page/agorarecursos/components/img/3_20.png";
import importedImg21 from "@/page/agorarecursos/components/img/3_21.png";
import importedImg22 from "@/page/agorarecursos/components/img/3_22.png";
import importedImg23 from "@/page/agorarecursos/components/img/3_23.png";
import importedImg24 from "@/page/agorarecursos/components/img/3_24.png";
import importedImg25 from "@/page/agorarecursos/components/img/3_25.png";
import importedImg26 from "@/page/agorarecursos/components/img/3_26.png";
import importedImg27 from "@/page/agorarecursos/components/img/3_27.png";
// import importedImg28 from "@/page/agorarecursos/components/img/3_28.png";

import AnswerImg3 from "@/page/agorarecursos/components/img/3_3_3.png";
import AnswerImg4 from "@/page/agorarecursos/components/img/3_4_4.png";
import AnswerImg5 from "@/page/agorarecursos/components/img/3_5_5.png";
import AnswerImg6 from "@/page/agorarecursos/components/img/3_6_6.png";
import AnswerImg7 from "@/page/agorarecursos/components/img/3_7_7.png";
import AnswerImg8 from "@/page/agorarecursos/components/img/3_8_8.png";
import AnswerImg9 from "@/page/agorarecursos/components/img/3_9_9.png";
import AnswerImg10 from "@/page/agorarecursos/components/img/3_10_10.png";
import AnswerImg11 from "@/page/agorarecursos/components/img/3_11_11.png";
import AnswerImg12 from "@/page/agorarecursos/components/img/3_12_12.png";
import AnswerImg13 from "@/page/agorarecursos/components/img/3_13_13.png";
import AnswerImg14 from "@/page/agorarecursos/components/img/3_14_14.png";
import AnswerImg15 from "@/page/agorarecursos/components/img/3_15_15.png";
import AnswerImg16 from "@/page/agorarecursos/components/img/3_16_16.png";
import AnswerImg17 from "@/page/agorarecursos/components/img/3_17_17.png";
import AnswerImg18 from "@/page/agorarecursos/components/img/3_18_18.png";
import AnswerImg19 from "@/page/agorarecursos/components/img/3_19_19.png";
import AnswerImg20 from "@/page/agorarecursos/components/img/3_20_20.png";
import AnswerImg21 from "@/page/agorarecursos/components/img/3_21_21.png";
import AnswerImg22 from "@/page/agorarecursos/components/img/3_22_22.png";
import AnswerImg23 from "@/page/agorarecursos/components/img/3_23_23.png";
import AnswerImg24 from "@/page/agorarecursos/components/img/3_24_24.png";
import AnswerImg25 from "@/page/agorarecursos/components/img/3_25_25.png";
import AnswerImg26 from "@/page/agorarecursos/components/img/3_26_26.png";
import AnswerImg27 from "@/page/agorarecursos/components/img/3_27_27.png";
// import AnswerImg28 from "@/page/agorarecursos/components/img/3_28_28.png";
export default {
  data() {
    return {
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      importedImg6: importedImg6,
      importedImg7: importedImg7,
      importedImg8: importedImg8,
      importedImg9: importedImg9,
      importedImg10: importedImg10,
      importedImg11: importedImg11,
      importedImg12: importedImg12,
      importedImg13: importedImg13,
      importedImg14: importedImg14,
      importedImg15: importedImg15,
      importedImg16: importedImg16,
      importedImg17: importedImg17,
      importedImg18: importedImg18,
      importedImg19: importedImg19,
      importedImg20: importedImg20,
      importedImg21: importedImg21,
      importedImg22: importedImg22,
      importedImg23: importedImg23,
      importedImg24: importedImg24,
      importedImg25: importedImg25,
      importedImg26: importedImg26,
      importedImg27: importedImg27,
      // importedImg28: importedImg28,
      AnswerImg3: AnswerImg3,
      AnswerImg4: AnswerImg4,
      AnswerImg5: AnswerImg5,
      AnswerImg6: AnswerImg6,
      AnswerImg7: AnswerImg7,
      AnswerImg8: AnswerImg8,
      AnswerImg9: AnswerImg9,
      AnswerImg10: AnswerImg10,
      AnswerImg11: AnswerImg11,
      AnswerImg12: AnswerImg12,
      AnswerImg13: AnswerImg13,
      AnswerImg14: AnswerImg14,
      AnswerImg15: AnswerImg15,
      AnswerImg16: AnswerImg16,
      AnswerImg17: AnswerImg17,
      AnswerImg18: AnswerImg18,
      AnswerImg19: AnswerImg19,
      AnswerImg20: AnswerImg20,
      AnswerImg21: AnswerImg21,
      AnswerImg22: AnswerImg22,
      AnswerImg23: AnswerImg23,
      AnswerImg24: AnswerImg24,
      AnswerImg25: AnswerImg25,
      AnswerImg26: AnswerImg26,
      AnswerImg27: AnswerImg27,
      // AnswerImg28: AnswerImg28,

      altText: "图片",
      showNewImage2: false,
      showNewImage3: false,
      showNewImage4: false,
      showNewImage5: false,
      showNewImage6: false,
      showNewImage7: false,
      showNewImage8: false,
      showNewImage9: false,
      showNewImage10: false,
      showNewImage11: false,
      showNewImage12: false,
      showNewImage13: false,
      showNewImage14: false,
      showNewImage15: false,
      showNewImage16: false,
      showNewImage17: false,
      showNewImage18: false,
      showNewImage19: false,
      showNewImage20: false,
      showNewImage21: false,
      showNewImage22: false,
      showNewImage23: false,
      showNewImage24: false,
      showNewImage25: false,
      showNewImage26: false,
      showNewImage27: false,
      activeImage: 0,
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    toggleNewImage(imageIndex) {
      //  console.log("点击了");
      //   this.$set(this.showNewImage, imageIndex, !this.showNewImage[imageIndex]);
      //   this.activeImage = imageIndex;
      console.log(this.showNewImage3);
      if (imageIndex == 2) {
        this.showNewImage2 = !this.showNewImage2;
      }
      if (imageIndex == 3) {
        this.showNewImage3 = !this.showNewImage3;
      }
      if (imageIndex == 4) {
        this.showNewImage4 = !this.showNewImage4;
      }
      if (imageIndex == 5) {
        this.showNewImage5 = !this.showNewImage5;
      }
      if (imageIndex == 6) {
        this.showNewImage6 = !this.showNewImage6;
      }
      if (imageIndex == 7) {
        this.showNewImage7 = !this.showNewImage7;
      }
      if (imageIndex == 8) {
        this.showNewImage8 = !this.showNewImage8;
      }
      if (imageIndex == 9) {
        this.showNewImage9 = !this.showNewImage9;
      }
      if (imageIndex == 10) {
        this.showNewImage10 = !this.showNewImage10;
      }
      if (imageIndex == 11) {
        this.showNewImage11 = !this.showNewImage11;
      }
      if (imageIndex == 12) {
        this.showNewImage12 = !this.showNewImage12;
      }
      if (imageIndex == 13) {
        this.showNewImage13 = !this.showNewImage13;
      }
      if (imageIndex == 14) {
        this.showNewImage14 = !this.showNewImage14;
      }
      if (imageIndex == 15) {
        this.showNewImage15 = !this.showNewImage15;
      }
      if (imageIndex == 16) {
        this.showNewImage16 = !this.showNewImage16;
      }
      if (imageIndex == 17) {
        this.showNewImage17 = !this.showNewImage17;
      }
      if (imageIndex == 18) {
        this.showNewImage18 = !this.showNewImage18;
      }
      if (imageIndex == 19) {
        this.showNewImage19 = !this.showNewImage19;
      }
      if (imageIndex == 20) {
        this.showNewImage20 = !this.showNewImage20;
      }
      if (imageIndex == 21) {
        this.showNewImage21 = !this.showNewImage21;
      }
      if (imageIndex == 22) {
        this.showNewImage22 = !this.showNewImage22;
      }
      if (imageIndex == 23) {
        this.showNewImage23 = !this.showNewImage23;
      }
      if (imageIndex == 24) {
        this.showNewImage24 = !this.showNewImage24;
      }
      if (imageIndex == 25) {
        this.showNewImage25 = !this.showNewImage25;
      }
      if (imageIndex == 26) {
        this.showNewImage26 = !this.showNewImage26;
      }
      if (imageIndex == 27) {
        this.showNewImage27 = !this.showNewImage27;
      }
      if (imageIndex == 28) {
        this.showNewImage28 = !this.showNewImage28;
      }
    },
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7,
.imgBox8,
.imgBox9,
.imgBox10,
.imgBox11,
.imgBox12,
.imgBox13,
.imgBox14,
.imgBox15,
.imgBox16,
.imgBox17,
.imgBox18,
.imgBox19,
.imgBox20,
.imgBox21,
.imgBox22,
.imgBox23,
.imgBox24,
.imgBox25,
.imgBox26,
.imgBox27,
.imgBox28,
.imgBox30 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "#FFFFFF";
}

img {
  display: block;
}
.img2,
.img3,
.img4,
.img5,
.img6,
.img7,
.img8,
.img9,
.img10,
.img11,
.img12,
.img13,
.img14,
.img15,
.img16,
.img17,
.img18,
.img19,
.img20,
.img21,
.img22,
.img23,
.img24,
.img25,
.img26,
.img27,
.img28 .img30 {
  width: 100%;
  overflow: hidden;
  justify-content: space-between;
  margin: 0;
}

.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7,
.imgItem8,
.imgItem9,
.imgItem10,
.imgItem11,
.imgItem12,
.imgItem13,
.imgItem14,
.imgItem15,
.imgItem16,
.imgItem17,
.imgItem18,
.imgItem19,
.imgItem20,
.imgItem21,
.imgItem22,
.imgItem23,
.imgItem24,
.imgItem25,
.imgItem26,
.imgItem27,
.imgItem28 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7,
.imgItem8,
.imgItem9,
.imgItem10,
.imgItem11,
.imgItem12,
.imgItem13,
.imgItem14,
.imgItem15,
.imgItem16,
.imgItem17,
.imgItem18,
.imgItem19,
.imgItem20,
.imgItem21,
.imgItem22,
.imgItem23,
.imgItem24,
.imgItem25,
.imgItem26,
.imgItem27,
.imgItem28 {
  margin-top: 10px;
  transform: scale(70%, 70%);
}
.imgItem2 {
  /* margin: 40px 0; */
}
.img28 {
  margin-bottom: 300px;
}
.img30 {
  padding: 80px 0 20px 0;
  align-self: stretch;
  color: #333333;
  text-align: center;
  font-family: "Roboto Flex";
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.imgBox {
  /* margin-top: 2px; */
}
</style>
