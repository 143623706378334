<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
        <div class="privacy">
          <a
            href="https://cdmaw.copardinero.com/help/privacy_agreement"
            class="privacy-link"
            target="_blank"
          ></a>
        </div>
        <ul class="skip">
          <li><router-link to="/" class="skip-link"> </router-link></li>
          <li>
            <router-link to="/introduce" class="skip-link"> </router-link>
          </li>
          <li><router-link to="/question" class="skip-link"> </router-link></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "./components/img/footer.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,

      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box {
  width: 100%;
  overflow: hidden;
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6 {
  width: 100%;
  margin: 0;
}

.img1Box {
  position: relative;
}

.privacy {
  position: absolute;
  width: 15%;
  height: 5%;
  top: 25%;
  left: 13%;
}
.privacy-link {
  display: block;
  width: 100%;
  height: 100%;
}
.privacy:hover {
  cursor: pointer;
}
.skip {
  position: absolute;
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
  width: 14%;
  height: 12%;
  top: 8%;
  left: 65%;
}
.skip li {
  flex: 1;
  /* border: 1px solid red; */
  width: 100%;
  height: 10%;
}
.skip-link{
  display: block;
  width: 100%;
  height: 100%;
}
.skip li:first-child {
  width: 70%;
}
.skip li:last-child {
  width: 40%;
}
.skip li:hover {
  cursor: pointer;
}
</style>
